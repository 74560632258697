import { useSelector } from "react-redux";
import useAgs from "./useAgs";
import { useEffect, useState } from "react";

const determineRole = (roles) => {
  if (!Array.isArray(roles) || roles.length === 0) return "users";
  if (roles.includes("managers")) {
    return roles.find((role) => role === "managers") || "users";
  } else if (roles.includes("rescue")) {
    return roles.find((role) => role === "rescue") || "users";
  } else {
    return roles[0];
  }
};

function useRole() {
  const [role, setRole] = useState("users");
  const ags = useAgs();
  const allAgsRoles = useSelector((state) => state.user?.data?.roles);

  useEffect(() => {
    if (!ags || !allAgsRoles) return;
    const roles = allAgsRoles[ags];
    const assignedRole = determineRole(roles);
    setRole(assignedRole);
  }, [ags, allAgsRoles]);

  return role;
}

export default useRole;

// import { useSelector } from "react-redux";
// import useAgs from "./useAgs";
// import { useEffect, useState } from "react";

// // return role => users | rescue | managers
// function useRole() {
//   const [role, setRole] = useState("users");
//   const ags = useAgs();
//   const allAgsRoles = useSelector((state) => state.user?.data?.roles);
//   useEffect(() => {
//     if (!ags) return;
//     if (!allAgsRoles) return;
//     if (!Array.isArray(allAgsRoles) || allAgsRoles.length === 0) return;
//     const roles = allAgsRoles[ags];

//     if (!roles) return;
//     const role = roles.includes("managers")
//       ? roles[1]
//       : roles.includes("rescue")
//       ? roles[1]
//       : roles[0];
//     setRole(role);
//   }, [ags, allAgsRoles]);
//   return role;
// }

// export default useRole;

// import { useSelector } from "react-redux";

// // return role => users | rescue | managers
// function useRole() {
//   const role = useSelector((state) => state.login.data?.user?.role);

//   const ags = useAgs();
//   const allAgsRoles = useSelector((state) => state.user?.data?.roles);
//   useEffect(() => {
//     if (!ags) return;
//     const roles = allAgsRoles[ags];

//     const role = roles.includes("managers")
//       ? roles[1]
//       : roles.includes("rescue")
//       ? roles[1]
//       : roles[0];
//   }, [ags, allAgsRoles]);
//   return role;
// }

// export default useRole;
